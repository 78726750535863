<div class="w-full">
  <div class="min-h-full justify-center items-center">
    <div class="w-full relative pb-100px">
      <div class="w-full h-48px flex items-center px-2 py-3" routerLink="/main">
        <div>
          <button class="flex items-center" type="button">
            <div class="relative w-32px h-32px">
              <span style="
                  box-sizing: border-box;
                  display: block;
                  overflow: hidden;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  position: absolute;
                  inset: 0px;
                "><img src="./../../../../assets/images/back.png" decoding="async" data-nimg="fill" style="
                    position: absolute;
                    inset: 0px;
                    box-sizing: border-box;
                    padding: 0px;
                    border: none;
                    margin: auto;
                    display: block;
                    width: 0px;
                    height: 0px;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100%;
                    max-height: 100%;
                  " /></span>
            </div>
          </button>
        </div>
        <div class="flex-grow text-center mr-8 font-700 leading-1-3 text-17px truncate">
          ติดต่อพนักงาน
        </div>
      </div>
      <div class="w-full h-270px absolute overflow-hidden bg-gradient-to-b from-EE5F28 to-000 z-10">
        <div class="absolute right-7 pr-17px w-157px h-138px">
          <span style="
              box-sizing: border-box;
              display: block;
              overflow: hidden;
              width: initial;
              height: initial;
              background: none;
              opacity: 1;
              border: 0px;
              margin: 0px;
              padding: 0px;
              position: absolute;
              inset: 0px;
            "><img alt="admin-icon" src="./../../../../assets/images/admin-contact.png" decoding="async"
              data-nimg="fill" class="-z-40" style="
                position: absolute;
                inset: 0px;
                box-sizing: border-box;
                padding: 0px;
                border: none;
                margin: auto;
                display: block;
                width: 0px;
                height: 0px;
                min-width: 100%;
                max-width: 100%;
                min-height: 100%;
                max-height: 100%;
                object-fit: contain;
              " /></span>
        </div>
        <span style="
            box-sizing: border-box;
            display: block;
            overflow: hidden;
            width: initial;
            height: initial;
            background: none;
            opacity: 1;
            border: 0px;
            margin: 0px;
            padding: 0px;
            position: absolute;
            inset: 0px;
          "><img alt="" src="./../../../../assets/images/blur_contact_bg.png" decoding="async" data-nimg="fill"
            class="-z-30" style="
              position: absolute;
              inset: 0px;
              box-sizing: border-box;
              padding: 0px;
              border: none;
              margin: auto;
              display: block;
              width: 0px;
              height: 0px;
              min-width: 100%;
              max-width: 100%;
              min-height: 100%;
              max-height: 100%;
              object-fit: fill;
            " /></span>
        <div class="flex flex-col justify-start pt-2 mx-4 drop-shadow-md xs:pl-4">
          <div class="font-medium text-16px pt-2">สวัสดี robin789</div>
          <div class="text-20px font-semibold">เราพร้อมจะช่วยเหลือคุณ</div>
        </div>
      </div>
      <div class="relative top-100px mx-4 z-20">
        <div class="bg-161616 border border-24221E pt-1 pb-3 rounded-[7.25px]">
          <div class="primary-gold font-bold text-14px text-center py-2">
            แจ้งปัญหา
          </div>
          <form>
            <div class="grid grid-cols-2 gap-1 mx-4 font-semibold text-10px leading-13px">
              <button type="button" class="bg-EE5F28 text-white h-7 h-33px text-909090 bg-100F12">
                ปัญหาด้านการบริการ</button><button type="button" class="false h-7 h-33px text-909090 bg-100F12">
                ปัญหาด้านการเงิน</button><button type="button" class="false h-7 h-33px text-909090 bg-100F12">
                ปัญหาด้านการใช้ระบบ</button><button type="button" class="false h-7 h-33px text-909090 bg-100F12">
                แก้ไขบัญชี หรือ อื่นๆ
              </button>
            </div>
            <div class="mx-4 pt-3">
              <textarea id="message" rows="6"
                class="block p-2.5 w-full resize-none text-sm text-white rounded-lg outline-none bg-100F12 placeholder-757575 border border-24221E focus:ring-0 placeholder:text-xs placeholder: xs:text-xs"
                placeholder="ใส่ข้อมูลร้องเรียน" name="comment"></textarea><button
                class="h-10 h-44px w-full flex justify-center mt-3.5 mb-1 font-medium text-16px leading-19px secondary-button pointer-events-none"
                type="submit">
                ส่งข้อความ
              </button>
            </div>
          </form>
        </div>
        <div class="primary-gold font-bold text-10px pt-2 pb-1 my-3 mx-4">
          ช่องทางติดต่ออื่นๆ
        </div>
        <div class="flex flex-row mx-4 mb-16">
          <div class="w-1-12 mx-auto">
            <div class="relative w-6 h-6 w-30px h-30px">
              <span style="
                  box-sizing: border-box;
                  display: block;
                  overflow: hidden;
                  width: initial;
                  height: initial;
                  background: none;
                  opacity: 1;
                  border: 0px;
                  margin: 0px;
                  padding: 0px;
                  position: absolute;
                  inset: 0px;
                "><img alt="line-icon" src="./../../../../assets/images/line.png" decoding="async" data-nimg="fill"
                  class="rounded-[50px]" style="
                    position: absolute;
                    inset: 0px;
                    box-sizing: border-box;
                    padding: 0px;
                    border: none;
                    margin: auto;
                    display: block;
                    width: 0px;
                    height: 0px;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100%;
                    max-height: 100%;
                    object-fit: contain;
                  " /></span>
            </div>
          </div>
          <p class="pl-3 w-9/12 text-10px">Line ID <br />@55inter</p>
          <div class="w-2/12 my-auto">
            <div class="bg-38C142 w-full h-6 xs:h-7 flex justify-center text-10px font-bold rounded-md cursor-pointer">
              <a class="self-center" href="https://lin.ee/W8RwWd4">คลิก</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>