import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ChatInnerComponent } from './chat-inner.component';
import { ThaiDatePipe } from './thaidate.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
@NgModule({
  declarations: [ChatInnerComponent, ThaiDatePipe],
  imports: [CommonModule, ScrollingModule, InlineSVGModule],
  exports: [ChatInnerComponent],
})
export class ChatInnerModule { }
