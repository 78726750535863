<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Latest Orders</span>
    <span class="text-muted mt-1 fw-bold fs-7">More than 100 new orders</span>
  </h3>
  <div class="card-toolbar">
    <ul class="nav">
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_7_tab_1')"
          (click)="setTab('kt_table_widget_7_tab_1')"
        >
          Month
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_7_tab_2')"
          (click)="setTab('kt_table_widget_7_tab_2')"
        >
          Week
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_7_tab_3')"
          (click)="setTab('kt_table_widget_7_tab_3')"
        >
          Day
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <div class="tab-content">
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_7_tab_1')"
      id="kt_table_widget_7_tab_1"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-success">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/coding/cod002.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-success"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Media Device
                </a>
                <span class="text-muted fw-bold d-block fs-7">
                  Voice and video recorder
                </span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">5 day ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-success fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/general/gen024.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-danger"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Special Meal
                </a>
                <span class="text-muted fw-bold d-block fs-7">Quona Rice</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">2 day ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-danger fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-primary">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/general/gen005.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-primary"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  New Users
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >Awesome Users</span
                >
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">4 day ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-primary fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/abstract/abs027.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-warning"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Active Customers
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >Best Customers</span
                >
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">1 day ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-warning fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/art/art007.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-info"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Strawberry Boxes
                </a>
                <span class="text-muted fw-bold d-block fs-7">From Spain</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">7 day ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-info fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_7_tab_2')"
      id="kt_table_widget_7_tab_2"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/abstract/abs042.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-info"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Strawberry Boxes
                </a>
                <span class="text-muted fw-bold d-block fs-7">From Spain</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">4 week ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-info fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/general/gen024.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-danger"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Special Meal
                </a>
                <span class="text-muted fw-bold d-block fs-7">Quona Rice</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">2 week ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-danger fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/abstract/abs027.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-warning"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Active Customers
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >Best Customers</span
                >
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7">5 week ago</span>
              </td>
              <td class="text-end">
                <span class="badge badge-light-warning fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_7_tab_3')"
      id="kt_table_widget_7_tab_3"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-success">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/ecommerce/ecm002.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-success"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Media Device
                </a>
                <span class="text-muted fw-bold d-block fs-7">
                  Voice and video recorder
                </span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7"
                  >3 month ago</span
                >
              </td>
              <td class="text-end">
                <span class="badge badge-light-success fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-danger">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/general/gen024.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-danger"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Special Meal
                </a>
                <span class="text-muted fw-bold d-block fs-7">Quona Rice</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7"
                  >5 month ago</span
                >
              </td>
              <td class="text-end">
                <span class="badge badge-light-danger fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-warning">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/abstract/abs027.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-warning"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Active Customers
                </a>
                <span class="text-muted fw-bold d-block fs-7"
                  >Best Customers</span
                >
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7"
                  >6 month ago</span
                >
              </td>
              <td class="text-end">
                <span class="badge badge-light-warning fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label bg-light-info">
                    <span
                      [inlineSVG]="
                        './assets/media/icons/duotune/abstract/abs042.svg'
                      "
                      class="svg-icon svg-icon-2x svg-icon-info"
                    ></span>
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Strawberry Boxes
                </a>
                <span class="text-muted fw-bold d-block fs-7">From Spain</span>
              </td>
              <td class="text-end">
                <span class="text-muted fw-bold d-block fs-8">Ordered</span>
                <span class="text-dark fw-bolder d-block fs-7"
                  >4 month ago</span
                >
              </td>
              <td class="text-end">
                <span class="badge badge-light-info fs-7 fw-bolder"
                  >Delivered</span
                >
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <i class="bi bi-three-dots fs-5"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
  </div>
</div>
<!-- end::Body -->
