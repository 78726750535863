import { Routes } from "@angular/router";
import { AuthGuard } from "../modules/auth/services/auth.guard";
import { ContactComponent } from "../modules/contact/contact.component";

const Routing: Routes = [
  {
    path: "dashboard",
    redirectTo: "/apps/tickets-system",
  },

  {
    path: "builder",
    loadChildren: () =>
      import("./builder/builder.module").then((m) => m.BuilderModule),
  },
  {
    path: 'member',
    loadChildren: () =>
      import('./../modules/member/member.module').then((m) => m.MemberModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'financial',
    loadChildren: () =>
      import('./../modules/financial/financial.module').then((m) => m.FinancialModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'history',
    loadChildren: () =>
      import('./../modules/history/history.module').then((m) => m.HistoryModule),
    // canActivate: [AuthGuard],
  },

  {
    path: 'contact',
    component: ContactComponent,
  },

  {
    path: "apps/chat",
    loadChildren: () =>
      import("../modules/apps/chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "apps/tickets-system",
    loadChildren: () =>
      import("../modules/apps/tickets-system/tickets-system.module").then(
        (m) => m.TicketsSystemModule
      ),
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('../modules/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./../modules/main/main.module').then((m) => m.MainModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'games',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./../modules/games/games.module').then((m) => m.GamesModule),
  },
  {
    path: 'promotions',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./../modules/promotion/promotion.module').then((m) => m.PromotionModule),
  },
  {
    path: '**',
    redirectTo: '/main',
  },
  // {
  //   path: "**",
  //   redirectTo: "error/404",
  // },
];

export { Routing };
