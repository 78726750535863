<!--begin::Container-->

<div class="nav-bar">
  <div class="container h-100">
    <div class="w-100 h-100 d-md-flex d-none align-items-center py-3">
      <div class="heigt-icon" routerLink="/main">
        <img src="./assets/static/nav-v2/logo-pgslot18-2.png" style="height: 50px" />
        <!-- <img src="./assets/static/nav-v2/logo-sevenday-small.png" class="" style="height: 30px" alt="" /> -->
      </div>
      <div class="d-flex ms-3">
        <a class="nav-v2-link px-3" routerLink="/main">
          <div class="text-md-center text-sm-start">หน้าแรก</div>
        </a>
        <ng-container *ngIf="isLoggin === false">
          <a class="nav-v2-link px-3" routerLink="/auth/login">
            <div class="text-md-center text-sm-start">เข้าสู่ระบบ</div>
          </a>
          <a class="nav-v2-link px-3" routerLink="/auth/registration">
            <div class="text-md-center text-sm-start">สมัครสมาชิก</div>
          </a>
        </ng-container>
        <a class="nav-v2-link px-3" routerLink="/promotion">
          <div class="text-md-center text-sm-start">โปรโมชั่น</div>
        </a>
        <a class="nav-v2-link px-3" href="https://lin.ee/x9nTvsf">
          <div class="text-md-center text-sm-start">ติดต่อเรา</div>
        </a>
      </div>
      <div class="me-auto"></div>
      <div class="d-flex" *ngIf="isLoggin === false">
        <a class="text-white me-2" style="text-decoration: none" routerLink="/auth/registration">
          <div class="action-btn-nav-v2 text-center" style="background-color: #ff1011">
            สมัครสมาชิก
          </div>
        </a>
        <a class="text-white" style="text-decoration: none" routerLink="/auth/login">
          <div class="action-btn-nav-v2 text-center" style="background-color: #222222">
            เข้าสู่ระบบ
          </div>
        </a>
      </div>
      <div class="d-flex" *ngIf="isLoggin === true">
        <a class="me-3" style="text-decoration: none">
          <div class="text-center btn-action register" style="
              background-image: linear-gradient(
                to right,
                #232526 0%,
                #414345 51%,
                #232526 100%
              );
              color: white;
              display: flex;
                align-items: center;
            "> <img src="./assets/static/nav-v2/wrapcoin.gif" style="height: 15px" alt="" />
            &nbsp;
            {{ credit | number : "0.2-2" }}
          </div>
        </a>
        <a class="text-white me-2" style="text-decoration: none" (click)="logOut()">
          <div class="action-btn-nav-v2 text-center" style="background-color: #ff1011">
            ออกจากระบบ
          </div>
        </a>
      </div>
    </div>
    <div class="h-100 py-3 d-flex d-md-none justify-content-between align-items-center">
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <div class="heigt-icon-sm d-flex justify-content-center align-items-center" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling" style="cursor: pointer; width: 50px">
            <img src="./assets/static/nav-v2/logo-pgslot18.png" style="width: 100%" alt="" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div class="" routerLink="/main">
            <img src="./assets/static/nav-v2/logo-pgslot18-2.png" style="height: 50px" alt="" />
          </div>
        </div>
      </div>

      <div class="d-flex">
        <ng-container *ngIf="isLoggin === true">
          <a class="me-3" style="text-decoration: none">
            <div class="text-center btn-action register" style="
                background-image: linear-gradient(
                  to right,
                  #232526 0%,
                  #414345 51%,
                  #232526 100%
                );
                color: white;
                display: flex;
                align-items: center;
              ">
              <img src="./assets/static/nav-v2/wrapcoin.gif" style="height: 15px" alt="" />
              &nbsp; {{ credit | number : "0.2-2" }}
            </div>
          </a>
          <!-- <a class="me-1" style="text-decoration: none" (click)="logOut()">
            <div class="text-center btn-action register" style="background-color: #ff1011">
              ออกจากระบบ
            </div>
          </a> -->
        </ng-container>
        <ng-container *ngIf="isLoggin === false">
          <a class="me-1" style="text-decoration: none" routerLink="/auth/registration">
            <div class="text-center btn-action register" style="background-color: #ff1011">
              สมัครสมาชิก
            </div>
          </a>
          <a style="text-decoration: none" routerLink="/auth/login">
            <div class="text-center btn-action login" style="background-color: #222222">
              เข้าสู่ระบบ
            </div>
          </a></ng-container>
      </div>
    </div>
  </div>
</div>
<!-- <div [ngClass]="headerContainerCssClasses"
  class="d-flex align-items-stretch justify-content-between z-50 border-b border-F6A32F bg-black"
  id="kt_header_container">
  <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 w-lg-225px me-5">

    <div class="btn btn-icon btn-active-icon-primary ms-n2 me-2 d-flex d-lg-none" id="kt_aside_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs015.svg'" class="svg-icon svg-icon-1"></span>
    </div>
  </div>
  <div class="d-flex align-items-stretch justify-content-end flex-lg-grow-1">
    <app-topbar class="d-flex align-items-stretch flex-shrink-0"></app-topbar>
  </div>
</div> -->