<div class="stick-footer d-flex d-md-none align-items-center justify-content-center">
  <div class="col-around d-flex justify-content-center align-items-end">
    <a routerLink="/main" style="text-decoration: none !important">
      <div class="text-center">
        <img src="/assets/static/footer-v2/home.png" style="width: 40px" />
        <p class="m-0 stick-footer-text">หน้าหลัก</p>
      </div>
    </a>
  </div>
  <div class="col-around d-flex justify-content-center align-items-end">
    <a routerLink="/history" style="text-decoration: none !important">
      <div class="text-center">
        <img src="/assets/static/footer-v2/regis icon@3x.png" style="width: 40px" />
        <p class="m-0 stick-footer-text">รายการเงิน</p>
      </div>
    </a>
  </div>
  <div class="col-around d-flex justify-content-center align-items-end">
    <a routerLink="/member/affiliate" style="text-decoration: none !important">
      <div class="text-center">
        <span>
          <img src="/assets/static/footer-v2/777.webp" style="width: 65px" />
        </span>
        <p class="m-0 stick-footer-text">ระบบเซลล์</p>
      </div>
    </a>
  </div>

  <div class="col-around d-flex justify-content-center align-items-end">
    <a routerLink="/promotions" style="text-decoration: none !important">
      <div class="text-center">
        <img src="/assets/static/footer-v2/promotion@3x.png" style="width: 40px" />
        <p class="m-0 stick-footer-text">โปรโมชั่น</p>
      </div>
    </a>
  </div>
  <div class="col-around d-flex justify-content-center align-items-end">
    <a routerLink="/member/info" style="text-decoration: none !important">
      <div class="text-center">
        <img src="/assets/static/footer-v2/profile.png" style="width: 40px" />
        <p class="m-0 stick-footer-text">ตั้งค่า</p>
      </div>
    </a>
  </div>
</div>