<!--begin::Subscription-->
<div class="mx-5">
  <!--begin::Container-->
  <div class="text-center pt-10 mb-20">
    <!--begin::Title-->
    <h2 class="fs-2 fw-bolder mb-7">My Subscription</h2>
    <!--end::Title-->

    <!--begin::Description-->
    <p class="text-gray-400 fs-4 fw-bold mb-10">
      There are no customers added yet.<br />
      Kickstart your CRM by adding a your first customer
    </p>
    <!--end::Description-->

    <!--begin::Action-->
    <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Upgrade Plan</a>
    <!--end::Action-->
  </div>
  <!--end::Container-->

  <!--begin::Illustration-->
  <div class="text-center px-4">
    <img src="./assets/media/illustrations/sigma-1/18.png" alt="" class="mw-100 mh-300px" />
  </div>
  <!--end::Illustration-->
</div>
<!--end::Subscription-->
