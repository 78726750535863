<ng-container *ngIf="title$ | async as title">
  <ng-container *ngIf="showTitle">
    <!--begin::Title-->
    <h1 class="d-flex align-items-center my-1">
      <span class="text-dark fw-bolder fs-1">
        {{ title }}
      </span>

      <ng-container *ngIf="description$ | async as description">
        <ng-container *ngIf="showDescritption">
          <!--begin::Description-->
          <small class="text-muted fs-6 fw-bold ms-1">
            {{description}}
          </small>
          <!--end::Description-->
        </ng-container>
      </ng-container>
    </h1>
    <!--end::Title-->
    <!-- end::Title -->
  </ng-container>

  <ng-container *ngIf="showBC">
    <ng-container *ngIf="bc$ | async as _bc">
      <ng-container *ngIf="_bc.length > 0">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7"
          [ngClass]="{'my-1' : pageTitleDirection === 'row', 'pt-1': pageTitleDirection !== 'row'}">
          <li *ngFor="let bc of _bc" class="breadcrumb-item" [ngClass]="{'text-muted': !bc.isSeparator}">
            <ng-container *ngIf="!bc.isSeparator">
              <a class="text-muted text-hover-primary" [routerLink]="bc.path">
                {{ bc.title }}
              </a>
            </ng-container>
            <ng-container *ngIf="bc.isSeparator">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </ng-container>
          </li>
          <li class="breadcrumb-item text-dark">{{ title }}</li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>