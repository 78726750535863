import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptions-tab',
  templateUrl: './subscriptions-tab.component.html',
  styleUrls: ['./subscriptions-tab.component.scss']
})
export class SubscriptionsTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
