<!-- All questions -->
<div class="menu-item py-3" routerLinkActive="here show">
  <a
    class="menu-link menu-center"
    routerLink="/apps/tickets-system/main"
    routerLinkActive="show">
    <span class="menu-icon">
    <span
      [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'"
      class="svg-icon svg-icon-2"></span>
  </span>
  </a>
</div>

<!-- Layout builder -->
<div class="menu-item py-3" routerLinkActive="here show">
  <a
    class="menu-link menu-center"
    routerLink="/builder"
    routerLinkActive="show">
    <span class="menu-icon">
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
      class="svg-icon svg-icon-2"></span>
  </span>
  </a>
</div>

<!-- Pages -->
<div
  class="menu-item py-3"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link menu-center">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'">
      </span>
    </span>
  </span>
  <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4" routerLinkActive="menu-active-bg show">
    <div
      class="menu-item"
      routerLinkActive="here show"
      data-kt-menu-trigger="click">
      <span class="menu-link">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
        <span class="menu-title" data-link="/crafted/pages/profile">Profile</span>
        <span class="menu-arrow"></span>
      </span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg show"
      >
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/overview"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Overview</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/projects"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Projects</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/campaigns"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Campaigns</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/documents"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Documents</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/connections"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Connections</span></a
          >
        </div>
      </div>
    </div>
    <div
      class="menu-item"
      routerLinkActive="here show"
      data-kt-menu-trigger="click">
      <span class="menu-link">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
        <span class="menu-title" data-link="/crafted/pages/wizards">Wizards</span>
        <span class="menu-arrow"></span>
      </span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg show"
      >
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/horizontal"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Horizontal</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/vertical"
          ><span class="menu-bullet"
          ><span class="bullet bullet-dot"></span></span
          ><span class="menu-title">Vertical</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Accounts -->
<div
  class="menu-item py-3"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link menu-center">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com006.svg'">
      </span>
    </span>
  </span>
  <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4" routerLinkActive="menu-active-bg show">
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/account/overview">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Overview</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/account/settings">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Settings</span>
      </a>
    </div>
  </div>
</div>

<!-- Errors -->
<div
  class="menu-item py-3"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link menu-center">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"></span>
    </span>
  </span>
  <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4" routerLinkActive="menu-active-bg show">
    <div class="menu-item">
      <a
        class="menu-link"
        routerLink="/error/404"
        routerLinkActive="active">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Error 404</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLink="/error/500"
        routerLinkActive="active">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Error 500</span>
      </a>
    </div>
  </div>
</div>

<!-- Widgets -->
<div
  class="menu-item py-3"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link menu-center">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'">
      </span>
    </span>
  </span>
  <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4" routerLinkActive="menu-active-bg show">
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/lists">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Lists</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/statistics">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Statistics</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/charts">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Charts</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/mixed">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Mixed</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/tables">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Tables</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/crafted/widgets/feeds">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Feeds</span>
      </a>
    </div>
  </div>
</div>

<!-- Chat -->
<div
  class="menu-item py-3"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link menu-center">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'">
      </span>
    </span>
  </span>
  <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4" routerLinkActive="menu-active-bg show">
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/apps/chat/private-chat">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Private Chat</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/apps/chat/group-chat">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Group Chart</span>
      </a>
    </div>
    <div class="menu-item">
      <a
        class="menu-link"
        routerLinkActive="active"
        routerLink="/apps/chat/drawer-chat">
        <span class="menu-bullet"><span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">Drawer Chart</span>
      </a>
    </div>
  </div>
</div>

<!-- Changelog  -->
<div class="menu-item py-3">
  <a target="_blank" class="menu-link menu-center" [href]="appPreviewChangelogUrl">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
      ></span>
    </span>
  </a>
</div>
