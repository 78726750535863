<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Files</span>
    <span class="text-muted mt-1 fw-bold fs-7">Over 100 pending files</span>
  </h3>

  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive">
    <!-- begin::Table -->
    <table class="table align-middle gs-0 gy-3">
      <!-- begin::Table head -->
      <thead>
        <tr>
          <th class="p-0 w-50px"></th>
          <th class="p-0 min-w-150px"></th>
          <th class="p-0 min-w-140px"></th>
          <th class="p-0 min-w-120px"></th>
          <th class="p-0 min-w-40px"></th>
        </tr>
      </thead>
      <!-- end::Table head -->
      <!-- begin::Table body -->
      <tbody>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label bg-light-success">
                <span
                  [inlineSVG]="
                    './assets/media/icons/duotune/ecommerce/ecm002.svg'
                  "
                  class="svg-icon svg-icon-2x svg-icon-success"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Top Authors
            </a>
          </td>
          <td class="text-end text-muted fw-bold">ReactJs, HTML</td>
          <td class="text-end text-muted fw-bold">4600 Users</td>
          <td class="text-end text-dark fw-bolder fs-6 pe-0">5.4MB</td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label bg-light-danger">
                <span
                  [inlineSVG]="
                    './assets/media/icons/duotune/general/gen024.svg'
                  "
                  class="svg-icon svg-icon-2x svg-icon-danger"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Popular Authors
            </a>
          </td>
          <td class="text-end text-muted fw-bold">Python, MySQL</td>
          <td class="text-end text-muted fw-bold">7200 Users</td>
          <td class="text-end text-dark fw-bolder fs-6 pe-0">2.8MB</td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label bg-light-info">
                <span
                  [inlineSVG]="
                    './assets/media/icons/duotune/finance/fin006.svg'
                  "
                  class="svg-icon svg-icon-2x svg-icon-info"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              New Users
            </a>
          </td>
          <td class="text-end text-muted fw-bold">Laravel, Metronic</td>
          <td class="text-end text-muted fw-bold">890 Users</td>
          <td class="text-end text-dark fw-bolder fs-6 pe-0">1.5MB</td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label bg-light-warning"
                ><span
                  [inlineSVG]="
                    './assets/media/icons/duotune/abstract/abs027.svg'
                  "
                  class="svg-icon svg-icon-2x svg-icon-warning"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Active Customers
            </a>
          </td>
          <td class="text-end text-muted fw-bold">AngularJS, C#</td>
          <td class="text-end text-muted fw-bold">4600 Users</td>
          <td class="text-end text-dark fw-bolder fs-6 pe-0">5.4MB</td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label bg-light-primary">
                <span
                  [inlineSVG]="
                    './assets/media/icons/duotune/abstract/abs042.svg'
                  "
                  class="svg-icon svg-icon-2x svg-icon-primary"
                ></span>
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Active Customers
            </a>
          </td>
          <td class="text-end text-muted fw-bold">ReactJS, Ruby</td>
          <td class="text-end text-muted fw-bold">354 Users</td>
          <td class="text-end text-dark fw-bolder fs-6 pe-0">500KB</td>
        </tr>
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
</div>
<!-- begin::Body -->
