import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engages',
  templateUrl: './engages.component.html',
  styleUrls: ['./engages.component.scss']
})
export class EngagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
