<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <!-- <img src="./assets/media/logos/default.svg" alt="Logo" />
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg> -->
  <img
    class="animate__animated animate__rotateIn mb-3 pb-1"
    [src]="src"
    alt="Logo"
    width="250"
  />
  <h1
    class="animate__animated animate__fadeInLeft text-gold-gradient text-nowrap"
    style="font-size: 4rem; line-height: 100px !important"
  >
    Royal Gaming
  </h1>
  <!-- <h3
    class="animate__animated animate__fadeInRight text-gold-gradient"
    style="font-size: 2rem"
  >
    Royal-A9389.web.app
  </h3> -->
</div>
