import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NavigationCancel, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LayoutService } from "../../core/layout.service";
import { MenuComponent } from "../../../kt/components";
import * as crypto from 'crypto-js';
import { HttpClient } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  headerContainerCssClasses: string = "";
  showHeaderMenu: boolean = false;
  @ViewChild("ktPageTitle", { static: true }) ktPageTitle: ElementRef;

  private unsubscribe: Subscription[] = [];

  constructor(private layout: LayoutService, private http: HttpClient, private router: Router, private db: AngularFirestore, private appRef: ChangeDetectorRef) {
    this.routingChanges();
    const headerMenu = this.layout.getProp("header.menu") as
      | boolean
      | undefined;
    if (headerMenu) {
      this.showHeaderMenu = false;
    }
  }
  randomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  credit: any = 0
  isLoggin: any = false
  ngOnInit(): void {
    try {
      const key = environment.USERDATA_KEY;
      let agentdata = localStorage.getItem("dataToken")
      let sha = crypto.SHA256(key).toString();
      let md5 = crypto.MD5(sha).toString();
      let decData = crypto.enc.Base64.parse(agentdata).toString(
        crypto.enc.Utf8
      );
      let bytesa = crypto.AES.decrypt(decData || "", md5).toString(
        crypto.enc.Utf8
      );
      const jsonobj = JSON.parse(bytesa);
      this.isLoggin = true
      this.appRef.detectChanges();
      this.db
        .collection("members")
        .doc("memberinfo")
        .collection("data")
        .doc(jsonobj[0].id)
        .get()
        .subscribe((res: any) => {
          this.db
            .collection("members")
            .doc("memberinfo")
            .collection("data")
            .doc(jsonobj[0].id)
            .valueChanges()
            .subscribe((userdata: any) => {
              this.credit = userdata.credit
              this.appRef.detectChanges()
            })
          if (res.data().ingame === true) {
            let iduser = localStorage.getItem("id");
            var sha = crypto.SHA256(environment.USERDATA_KEY).toString();
            var md5 = crypto.MD5(sha).toString();
            let encJson = crypto.AES.encrypt(
              JSON.stringify({
                iduser: iduser,
                key: this.randomString(20)
              }),
              md5
            ).toString();
            let encData = crypto.enc.Base64.stringify(
              crypto.enc.Utf8.parse(encJson)
            );

            this.http
              .post(
                "https://asia-southeast1-royal-a9389.cloudfunctions.net/api/amb/slotLogOutPGSlot",
                { token: encData }
              )
              .subscribe((res: any) => {

                // console.log(res);
              });
          }
        });
    }
    catch {
      this.isLoggin = false
      this.appRef.detectChanges()
    }

    this.headerContainerCssClasses =
      this.layout.getStringCSSClasses("headerContainer");
  }
  logOut() {
    localStorage.clear();
    this.router.navigate(['/home']).then(() => {
      window.location.reload();
    });
  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        MenuComponent.reinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() { }
}
