export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAdMNdgndS2V3eDAgocq7vuQp6nvw7qTOg",
    authDomain: "royal-a9389.firebaseapp.com",
    projectId: "royal-a9389",
    storageBucket: "royal-a9389.appspot.com",
    messagingSenderId: "228472881323",
    appId: "1:228472881323:web:d5fe61c95b06b6653d77ee",
    measurementId: "G-3F0T74G3TY"
  },
  appVersion: 'v726demo2',
  USERDATA_KEY: 'J16SDyGk7XR1dZt3mwhu',
  isMockEnabled: false,
  appThemeName: "lotto",
  appPreviewUrl: "",
  appDemos: "",
  PLUSKEY: 'eDyCg2f3GUOvOdv2WNcjZrjvUu3YDWmI',
  appPurchaseUrl: "",
  appPreviewDocsUrl: "",
  appPreviewChangelogUrl: "",
  //apiUrl: 'https://asia-east2-center99-4c672.cloudfunctions.net/api/'
  apiUrl: 'http://localhost:3000/'
};
