import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error) {
        // You can perform actions based on the error here, such as showing a
        // custom error page, retrying the operation, etc.
        console.error('An error occurred:', error);
    }
}