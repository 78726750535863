import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto-js';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerContainerCssClasses: string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  constructor(private layout: LayoutService, private appRef: ChangeDetectorRef) { }
  tab: any = 1;
  isLoggin: any = false
  ngOnInit(): void {
    try {
      const key = environment.USERDATA_KEY;
      let agentdata = localStorage.getItem("dataToken")
      let sha = crypto.SHA256(key).toString();
      let md5 = crypto.MD5(sha).toString();
      let decData = crypto.enc.Base64.parse(agentdata).toString(
        crypto.enc.Utf8
      );
      let bytesa = crypto.AES.decrypt(decData || "", md5).toString(
        crypto.enc.Utf8
      );
      const jsonobj = JSON.parse(bytesa);
      this.isLoggin = true
      this.appRef.detectChanges();
    }
    catch {
      this.isLoggin = false
      this.appRef.detectChanges()
    }
    this.footerContainerCssClasses =
      this.layout.getStringCSSClasses('footerContainer');
  }
}
