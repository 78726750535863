import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thaidate'
})
export class ThaiDatePipe implements PipeTransform {
    transform(date: string, format: string): string {
        let ThaiDay = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์']
        let shortEngMonth = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        let shortThaiMonth = [
            'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
            'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
        ];
        let longThaiMonth = [
            'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
            'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
        ];
        let inputDate = new Date(date);
        let dataDate = [
            inputDate.getDay(), inputDate.getDate(), inputDate.getMonth(), inputDate.getFullYear(), inputDate.getHours(), inputDate.getMinutes(), inputDate.getSeconds()
        ];
        let outputDateFull = [
            'วัน ' + ThaiDay[dataDate[0]],
            'ที่ ' + dataDate[1],
            'เดือน ' + longThaiMonth[dataDate[2]],
            'พ.ศ. ' + (dataDate[3] + 543),
        ];
        let outputDateMini = [
            'วัน ' + ThaiDay[dataDate[0]],
            'ที่ ' + dataDate[1],
            'เดือน ' + shortThaiMonth[dataDate[2]],
            (dataDate[3] + 543),
        ];
        let outputDateShort = [
            dataDate[1] + '-',
            shortEngMonth[dataDate[2]] + '-',
            dataDate[3] + ' ',
            dataDate[4] + ':' + ('0' + dataDate[5]).slice(-2)
        ];
        let outputDateShortText = [
            dataDate[4] + ':' + ('0' + dataDate[5]).slice(-2)
        ];
        let outputDateMedium = [
            dataDate[1],
            longThaiMonth[dataDate[2]],
            dataDate[3] + 543,
            dataDate[4] + ':' + ('0' + dataDate[5]).slice(-2) + ':' + dataDate[6]
        ];
        let outputDateThai = [
            dataDate[1],
            shortEngMonth[dataDate[2]],
            dataDate[3],
        ];
        let outputDateMedium2 = [
            dataDate[1] + '-',
            shortEngMonth[dataDate[2]] + '-',
            dataDate[3] + ' รอบ ',
            dataDate[4] + ':' + dataDate[5]
        ];
        let outputDateMedium3 = [
            dataDate[1] + '-',
            shortEngMonth[dataDate[2]] + '-',
            dataDate[3] + ' ',
            dataDate[4] + ':' + dataDate[5]
        ];
        let outputDateThaiTime = [
            dataDate[1] + '-',
            shortEngMonth[dataDate[2]] + '-',
            dataDate[3],
            ' รอบ ' + dataDate[4] + ':' + ('0' + dataDate[5]).slice(-2)
        ];
        let outputDateTime = [
            dataDate[4] + ':' + ('0' + dataDate[5]).slice(-2)
        ];
        let returnDate: string;
        returnDate = outputDateMedium.join(" ");
        if (format == 'full') {
            returnDate = outputDateFull.join(" ");
        }
        if (format == 'medium') {
            returnDate = outputDateMedium.join(" ");
        }
        if (format == 'short') {
            returnDate = outputDateShort.join(" ");
        }
        if (format == 'short-text') {
            returnDate = outputDateShortText.join(" ");
        }
        if (format == 'short2') {
            returnDate = outputDateMedium3.join("");
        }
        if (format == 'time') {
            returnDate = outputDateTime.join(" ");
        }
        if (format == 'mini') {
            returnDate = outputDateMini.join(" ");
        }
        if (format == 'minithai') {
            returnDate = outputDateThai.join("-");
        }
        if (format == 'minithaitime') {
            returnDate = outputDateThaiTime.join("");
        }
        if (format == 'medium2') {
            // 
            returnDate = outputDateMedium2.join("");
        }
        return returnDate;
    }
}