<div id="kt_header_search" class="header-search d-flex align-items-center w-100" data-kt-search-keypress="true"
  data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu"
  data-kt-search-responsive="false" data-kt-menu-trigger="auto" data-kt-menu-permanent="true"
  data-kt-menu-placement="bottom-start" data-kt-search="true">

  <!--begin::Form(use d-none d-lg-block classes for responsive search)-->
  <form data-kt-search-element="form" class="w-100 position-relative mb-5 mb-lg-0" autocomplete="off">
    <!--begin::Hidden input(Added to disable form autocomplete)-->
    <input type="hidden">
    <!--end::Hidden input-->
    <!--begin::Icon-->
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
      class="svg-icon svg-icon-2 svg-icon-lg-3 svg-icon-gray-800 position-absolute top-50 translate-middle-y ms-5"></span>
    <!--end::Icon-->
    <!--begin::Input-->
    <input type="text" class="search-input form-control form-control-solid ps-13" name="search"
      [ngModel]="searchText | async" (ngModelChange)="updateSearchText($event)" placeholder="Search..."
      data-kt-search-element="input">
    <!--end::Input-->
    <ng-container *ngIf="showLoading">
      <!--begin::Spinner-->
      <span class="position-absolute top-50 end-0 translate-middle-y lh-0 me-5" data-kt-search-element="spinner">
        <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
      </span>
      <!--end::Spinner-->
    </ng-container>

    <ng-container *ngIf="!showLoading">
      <ng-container *ngIf="searchText | async">
        <!--begin::Reset-->
        <span (click)="resetSearchText()"
          class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-4"
          data-kt-search-element="clear">
          <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
            class="svg-icon svg-icon-2 svg-icon-lg-1 me-0"></span>
        </span>
        <!--end::Reset-->
      </ng-container>
    </ng-container>

  </form>
  <!--end::Form-->

  <ng-container>
    <!--begin::Menu-->
    <div data-kt-search-element="content"
      class="menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden" data-kt-menu="true"
      data-popper-placement="bottom-start">
      <!--begin::Wrapper-->
      <div data-kt-search-element="wrapper">
        <!--begin::Recently viewed-->
        <div data-kt-search-element="results">
          <!--begin::Items-->
          <div class="scroll-y mh-200px mh-lg-350px">
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pb-5" data-kt-search-element="category-title">Users</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img src="../assets/media/avatars/300-6.jpg" alt="">
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Karina Clark</span>
                <span class="fs-7 fw-bold text-muted">Marketing Manager</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img src="./assets/media/avatars/300-2.jpg" alt="">
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Olivia Bold</span>
                <span class="fs-7 fw-bold text-muted">Software Engineer</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img src="./assets/media/avatars/300-9.jpg" alt="">
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Ana Clark</span>
                <span class="fs-7 fw-bold text-muted">UI/UX Designer</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img src="./assets/media/avatars/300-14.jpg" alt="">
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Nick Pitola</span>
                <span class="fs-7 fw-bold text-muted">Art Director</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <img src="./assets/media/avatars/300-11.jpg" alt="">
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Edward Kulnic</span>
                <span class="fs-7 fw-bold text-muted">System Administrator</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Customers</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <img class="w-20px h-20px" src="./assets/media/svg/brand-logos/volicity-9.svg" alt="">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Company Rbranding</span>
                <span class="fs-7 fw-bold text-muted">UI Design</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <img class="w-20px h-20px" src="./assets/media/svg/brand-logos/tvit.svg" alt="">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Company Re-branding</span>
                <span class="fs-7 fw-bold text-muted">Web Development</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <img class="w-20px h-20px" src="./assets/media/svg/misc/infography.svg" alt="">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Business Analytics App</span>
                <span class="fs-7 fw-bold text-muted">Administration</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <img class="w-20px h-20px" src="./assets/media/svg/brand-logos/leaf.svg" alt="">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">EcoLeaf App Launch</span>
                <span class="fs-7 fw-bold text-muted">Marketing</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <img class="w-20px h-20px" src="./assets/media/svg/brand-logos/tower.svg" alt="">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column justify-content-start fw-bold">
                <span class="fs-6 fw-bold">Tower Group Website</span>
                <span class="fs-7 fw-bold text-muted">Google Adwords</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Category title-->
            <h3 class="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Projects</h3>
            <!--end::Category title-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <span class="fs-6 fw-bold">Si-Fi Project by AU Themes</span>
                <span class="fs-7 fw-bold text-muted">#45670</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <span class="fs-6 fw-bold">Shopix Mobile App Planning</span>
                <span class="fs-7 fw-bold text-muted">#45690</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/communication/com012.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <span class="fs-6 fw-bold">Finance Monitoring SAAS Discussion</span>
                <span class="fs-7 fw-bold text-muted">#21090</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
            <!--begin::Item-->
            <a href="#" class="d-flex text-dark text-hover-primary align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <span class="fs-6 fw-bold">Dashboard Analitics Launch</span>
                <span class="fs-7 fw-bold text-muted">#34560</span>
              </div>
              <!--end::Title-->
            </a>
            <!--end::Item-->
          </div>
          <!--end::Items-->
        </div>
        <!--end::Recently viewed-->
        <!--begin::Recently viewed-->
        <div class="" data-kt-search-element="main">
          <!--begin::Heading-->
          <div class="d-flex flex-stack fw-bold mb-4">
            <!--begin::Label-->
            <span class="text-muted fs-6 me-2">Recently Searched:</span>
            <!--end::Label-->
            <!--begin::Toolbar-->
            <div class="d-flex" data-kt-search-element="toolbar">
              <!--begin::Preferences toggle-->
              <div data-kt-search-element="preferences-show"
                class="btn btn-icon w-20px btn-sm btn-active-color-primary me-2 data-bs-toggle="
                title="Show search preferences">
                <!--begin::Svg Icon | path: icons/duotune/coding/cod001.svg-->
                <span class="svg-icon svg-icon-1">

                </span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Preferences toggle-->
              <!--begin::Advanced search toggle-->
              <div data-kt-search-element="advanced-options-form-show"
                class="btn btn-icon w-20px btn-sm btn-active-color-primary me-n1" data-bs-toggle="tooltip" title=""
                data-bs-original-title="Show more search options">
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                <span class="svg-icon svg-icon-2">

                </span>
                <!--end::Svg Icon-->
              </div>
              <!--end::Advanced search toggle-->
            </div>
            <!--end::Toolbar-->
          </div>
          <!--end::Heading-->
          <!--begin::Items-->
          <div class="scroll-y mh-200px mh-lg-325px">
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/electronics/elc004.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">BoomApp by Keenthemes</a>
                <span class="fs-7 text-muted fw-bold">#45789</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/graphs/gra001.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"Kept API Project Meeting</a>
                <span class="fs-7 text-muted fw-bold">#84050</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/graphs/gra006.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"KPI Monitoring App Launch</a>
                <span class="fs-7 text-muted fw-bold">#84250</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/graphs/gra002.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">Project Reference FAQ</a>
                <span class="fs-7 text-muted fw-bold">#67945</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"FitPro App Development</a>
                <span class="fs-7 text-muted fw-bold">#84250</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/finance/fin001.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">Shopix Mobile App</a>
                <span class="fs-7 text-muted fw-bold">#45690</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex align-items-center mb-5">
              <!--begin::Symbol-->
              <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                  <!--begin::Svg Icon | path: icons/duotune/graphs/gra002.svg-->
                  <span class="svg-icon svg-icon-2 svg-icon-primary">

                  </span>
                  <!--end::Svg Icon-->
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bold">"Landing UI Design" Launch</a>
                <span class="fs-7 text-muted fw-bold">#24005</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Item-->
          </div>
          <!--end::Items-->
        </div>
        <!--end::Recently viewed-->
        <!--begin::Empty-->
        <div data-kt-search-element="empty" class="text-center">
          <!--begin::Icon-->
          <div class="pt-10 pb-10">
            <!--begin::Svg Icon | path: icons/duotune/files/fil024.svg-->
            <span class="svg-icon svg-icon-4x opacity-50">
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Icon-->
          <!--begin::Message-->
          <div class="pb-15 fw-bold">
            <h3 class="text-gray-600 fs-5 mb-2">No result found</h3>
            <div class="text-muted fs-7">Please try again with a different query</div>
          </div>
          <!--end::Message-->
        </div>
        <!--end::Empty-->
      </div>
      <!--end::Wrapper-->
      <!--begin::Preferences-->
      <form data-kt-search-element="advanced-options-form" class="pt-1 ">
        <!--begin::Heading-->
        <h3 class="fw-bold text-dark mb-7">Advanced Search</h3>
        <!--end::Heading-->
        <!--begin::Input group-->
        <div class="mb-5">
          <input type="text" class="form-control form-control-sm form-control-solid" placeholder="Contains the word"
            name="query">
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5">
          <!--begin::Radio group-->
          <div class="nav-group nav-group-fluid">
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="type" value="has" checked="checked">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary">All</span>
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="type" value="users">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Users</span>
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="type" value="orders">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Orders</span>
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="type" value="projects">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Projects</span>
            </label>
            <!--end::Option-->
          </div>
          <!--end::Radio group-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5">
          <input type="text" name="assignedto" class="form-control form-control-sm form-control-solid"
            placeholder="Assigned to" value="">
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5">
          <input type="text" name="collaborators" class="form-control form-control-sm form-control-solid"
            placeholder="Collaborators" value="">
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5">
          <!--begin::Radio group-->
          <div class="nav-group nav-group-fluid">
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="attachment" value="has" checked="checked">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary">Has attachment</span>
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <label>
              <input type="radio" class="btn-check" name="attachment" value="any">
              <span class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">Any</span>
            </label>
            <!--end::Option-->
          </div>
          <!--end::Radio group-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="mb-5">
          <select name="timezone" aria-label="Select a Timezone" data-control="select2" data-placeholder="date_period"
            class="form-select form-select-sm form-select-solid select2-hidden-accessible"
            data-select2-id="select2-data-1-5x2l" tabindex="-1" aria-hidden="true">
            <option value="next" data-select2-id="select2-data-3-cvwk">Within the next</option>
            <option value="last">Within the last</option>
            <option value="between">Between</option>
            <option value="on">On</option>
          </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr"
            data-select2-id="select2-data-2-izjl" style="width: 100%;"><span class="selection"><span
                class="select2-selection select2-selection--single form-select form-select-sm form-select-solid"
                role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                aria-labelledby="select2-timezone-lb-container" aria-controls="select2-timezone-lb-container"><span
                  class="select2-selection__rendered" id="select2-timezone-lb-container" role="textbox"
                  aria-readonly="true" title="Within the next">Within the next</span><span
                  class="select2-selection__arrow" role="presentation"><b
                    role="presentation"></b></span></span></span><span class="dropdown-wrapper"
              aria-hidden="true"></span></span>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="row mb-8">
          <!--begin::Col-->
          <div class="col-6">
            <input type="number" name="date_number" class="form-control form-control-sm form-control-solid"
              placeholder="Lenght" value="">
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-6">
            <select name="date_typer" aria-label="Select a Timezone" data-control="select2" data-placeholder="Period"
              class="form-select form-select-sm form-select-solid select2-hidden-accessible"
              data-select2-id="select2-data-4-srky" tabindex="-1" aria-hidden="true">
              <option value="days" data-select2-id="select2-data-6-tqc0">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr"
              data-select2-id="select2-data-5-fz0h" style="width: 100%;"><span class="selection"><span
                  class="select2-selection select2-selection--single form-select form-select-sm form-select-solid"
                  role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false"
                  aria-labelledby="select2-date_typer-rx-container"
                  aria-controls="select2-date_typer-rx-container"><span class="select2-selection__rendered"
                    id="select2-date_typer-rx-container" role="textbox" aria-readonly="true"
                    title="Days">Days</span><span class="select2-selection__arrow" role="presentation"><b
                      role="presentation"></b></span></span></span><span class="dropdown-wrapper"
                aria-hidden="true"></span></span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <!--begin::Actions-->
        <div class="d-flex justify-content-end">
          <button type="reset" class="btn btn-sm btn-light fw-bolder btn-active-light-primary me-2"
            data-kt-search-element="advanced-options-form-cancel">Cancel</button>
          <a href="./../demo7/pages/search/horizontal.html" class="btn btn-sm fw-bolder btn-primary"
            data-kt-search-element="advanced-options-form-search">Search</a>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Preferences-->
      <!--begin::Preferences-->
      <form data-kt-search-element="preferences" class="pt-1">
        <!--begin::Heading-->
        <h3 class="fw-bold text-dark mb-7">Search Preferences</h3>
        <!--end::Heading-->
        <!--begin::Input group-->
        <div class="pb-4 border-bottom">
          <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Projects</span>
            <input class="form-check-input" type="checkbox" value="1" checked="checked">
          </label>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="py-4 border-bottom">
          <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Targets</span>
            <input class="form-check-input" type="checkbox" value="1" checked="checked">
          </label>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="py-4 border-bottom">
          <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Affiliate Programs</span>
            <input class="form-check-input" type="checkbox" value="1">
          </label>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="py-4 border-bottom">
          <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Referrals</span>
            <input class="form-check-input" type="checkbox" value="1" checked="checked">
          </label>
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="py-4 border-bottom">
          <label class="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
            <span class="form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2">Users</span>
            <input class="form-check-input" type="checkbox" value="1">
          </label>
        </div>
        <!--end::Input group-->
        <!--begin::Actions-->
        <div class="d-flex justify-content-end pt-7">
          <button type="reset" class="btn btn-sm btn-light fw-bolder btn-active-light-primary me-2"
            data-kt-search-element="preferences-dismiss">Cancel</button>
          <button type="submit" class="btn btn-sm fw-bolder btn-primary">Save Changes</button>
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Preferences-->
    </div>
    <!--end::Menu-->
  </ng-container>

</div>
