import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { first, take } from 'rxjs/operators';
import { interval, concat } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
    constructor(
        private appRef: ApplicationRef,
        private swUpdate: SwUpdate,
        private toast: ToastrService) {

    }
    public async init() {
        if (this.swUpdate.isEnabled) {
            const isNewVersion = await this.swUpdate.checkForUpdate();
            // Check if the new version is available
            console.log('isNewVersion: ' + isNewVersion)
            if (isNewVersion) {
                const isNewVersionActivated = await this.swUpdate.activateUpdate();
                // Check if the new version is activated and reload the app if it is
                if (isNewVersionActivated) document.location.reload();
            }
            // this.swUpdate.available.subscribe(event => {
            //     console.log('current version is', event.current);
            //     console.log('available version is', event.available);
            //     this.toast.warning('กรุณากดเพื่ออัตเดตแอพ.', 'อัพเดต', {
            //         disableTimeOut: true,
            //         positionClass: 'toast-top-right'
            //     }).onTap.subscribe(() => {
            //         this.swUpdate.activateUpdate().then(() => document.location.reload());
            //     });
            // });
            this.swUpdate.activated.subscribe(event => {
                console.log('old version was', event.previous);
                console.log('new version is', event.current);
            });
            // this.swUpdate.checkForUpdate().then(() => console.log('checking for new version..'))
            // const appIsStable = this.appRef.isStable.pipe(first(isStable => isStable === true));
            const everyDelay = interval(300000);
            const everyDelayOnceAppIsStable = everyDelay;
            // const everyDelayOnceAppIsStable = concat(appIsStable, everyDelay);
            console.log('begin Check..');
            everyDelayOnceAppIsStable.pipe().subscribe(() => this.swUpdate.checkForUpdate().then(async () => {
                console.log('checking for new version..')
                const isNewVersion = await this.swUpdate.checkForUpdate();
                // Check if the new version is available
                console.log('isNewVersion: ' + isNewVersion)
                if (isNewVersion) {
                    const isNewVersionActivated = await this.swUpdate.activateUpdate();
                    // Check if the new version is activated and reload the app if it is
                    if (isNewVersionActivated) document.location.reload();
                }
            }));
        }
    }
}
