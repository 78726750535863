<!--begin::Wrapper-->
<div class="m-0">
  <!--begin::Toolbar-->
  <div class="d-flex mb-10">

    <app-search></app-search>
    <app-search-result-inner></app-search-result-inner>

    <!--begin::Filter-->
    <div class="flex-shrink-0 ms-2">
      <!--begin::Menu toggle-->
      <button type="button" class="btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400"
        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen031.svg'" class="svg-icon-2"></span>
      </button>
      <!--end::Menu toggle-->
      <app-dropdown-menu1></app-dropdown-menu1>
    </div>
    <!--end::Filter-->
  </div>
  <!--end::Toolbar-->

  <!--begin::Projects-->
  <div class="m-0">
    <!--begin::Heading-->
    <h1 class="text-gray-800 fw-bold mb-6 mx-5">Projects</h1>
    <!--end::Heading-->

    <!--begin::Items-->
    <div class="mb-10" *ngFor="let item of allProjects">
      <!--begin::Item-->
      <span class="custom-list d-flex align-items-center px-5 py-4">
        <!--begin::Symbol-->
        <div class="symbol symbol-40px me-5">
          <span class="symbol-label">
            <img [src]="item.image" class="h-50 align-self-center" alt="" />
          </span>
        </div>
        <!--end::Symbol-->

        <!--begin::Description-->
        <div class="d-flex flex-column flex-grow-1">
          <!--begin::Title-->
          <h5 class="custom-list-title fw-bold text-gray-800 mb-1">
            {{item.title}}
          </h5>
          <!--end::Title-->

          <!--begin::Link-->
          <span class="text-gray-400 fw-bold">
            {{item.link}}
          </span>
          <!--end::Link-->
        </div>
        <!--begin::Description-->
      </span>
      <!--end::Item-->
    </div>
    <!--end::Items-->
  </div>
  <!--end::Projects-->
</div>
<!--end::Wrapper-->
